
import { hydrateCSRTemplate } from '@fiverr-private/layout-lib/CSRTemplate';
import { handleError } from '@fiverr-private/layout-lib/CSRTemplate/handleError';
import { loader } from '/home/circleci/app/packages/layout-sidebar-component/src/entries/Guest';
const getRootElement = () => typeof document !== 'undefined' && document.querySelector('#Sidebar');
const lazyImport = () => import(/* webpackChunkName: "SidebarGuestLazyComponent" */ '@fiverr-private/sidebar/entries/Guest');
const hydrateComponent = async () => {
    try {
        const { default: Component } = await lazyImport();
        const props = window.initialData['Sidebar'];
        const root = getRootElement();
        if (root) {
            hydrateCSRTemplate(Component, props, root, 'Sidebar', 'Guest');
        }
    } catch(error) {
        handleError(error, "Sidebar", "Guest");
    }
};
const main = async () => {
    try {
        const root = getRootElement();
        if (root) {
            await loader(hydrateComponent, root);
        }
    } catch(error) {
        handleError(error, "Sidebar", "Guest");
    }
};
main();
export default main;
